import { createStore } from 'vuex'
import Cumplimiento from '../services/cumplimiento'
const _Cumplimiento = new Cumplimiento()
const storeCardCumplimiento = {
  state: {
    _dataCumplimiento: {
      totalFacturadoAnio: 0
    }
    // _detalleCumplimiento: []
  },
  getters: {
    dataCumplimiento: state => state._dataCumplimiento
    // detalleCumplimiento: state => state._detalleCumplimiento
  },
  mutations: {
    setDataCumplimiento (state, payload) {
      state._dataCumplimiento = payload
    }
    // setDetalleCumplimiento (state, payload) {
    //   state._detalleCumplimiento = payload
    // }
  },
  actions: {
    getDataCumplimiento ({ commit }) {
        return new Promise((resolve) => {
          _Cumplimiento.getDataCumplimiento().then(({ data }) => {
            commit('setDataCumplimiento', data.data[0])
            resolve()
          })
        })
    }
    // getDetalleCumplimiento ({ commit }, payload) {
    //   return new Promise((resolve) => {
    //     _Cumplimiento.getDetalleCumplimiento(payload).then(({ data }) => {
    //       commit('setDetalleCumplimiento', data)
    //       resolve()
    //     })
    //   })
    // }
  }
}
const store = createStore(storeCardCumplimiento)
export default {
  namespaced: true,
  ...store
}
