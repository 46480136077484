import { createStore } from 'vuex'
import Pedidos from '../services/pedidos'
const _Pedidos = new Pedidos()
const storeCardPedidos = {
  state: {
    _dataPedidos: {
      CantPedidos: 0,
      RefSoli: 0,
      RefEntr: 0
    },
    _detallePedidos: []
  },
  getters: {
    dataPedidos: state => state._dataPedidos,
    detallePedidos: state => state._detallePedidos
  },
  mutations: {
    setDataPedidos (state, payload) {
      state._dataPedidos = payload
    },
    setDetallePedidos (state, payload) {
      state._detallePedidos = payload
    }
  },
  actions: {
    getDataPedidos ({ commit }, payload) {
        return new Promise((resolve) => {
          _Pedidos.getDataPedidos(payload).then(({ data }) => {
            commit('setDataPedidos', data.data[0])
            resolve()
          })
        })
    },
    getDetallePedidos ({ commit }, payload) {
      return new Promise((resolve) => {
        _Pedidos.getDetallePedidos(payload).then(({ data }) => {
          console.log('detalle', data)
          commit('setDetallePedidos', data.data)
          resolve()
        })
      })
    }
  }
}
const store = createStore(storeCardPedidos)
export default {
  namespaced: true,
  ...store
}
