import { createStore } from 'vuex'
import dayjs from 'dayjs'
const storeFiltro = {
  state: {
    _anio: dayjs().year(),
    _mes: dayjs().month()
  },
  getters: {
    anio: state => state._anio,
    mes: state => state._mes
  },
  mutations: {
     setAnio (state, payload) {
       state._anio = payload
     },
     setMes (state, payload) {
       state._mes = payload
     }
  }
}
const store = createStore(storeFiltro)
export default {
  namespaced: true,
  ...store
}
