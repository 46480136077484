import { createStore } from 'vuex'
import Facturacion from '../services/facturacion'
const _Facturacion = new Facturacion()
const storeCardFacturacion = {
  state: {
    _dataFacturacion: {
      totalFacturadoAnio: 0,
      totalFacturadoMes: 0,
      totalRadicadoAnio: 0,
      totalRadicadoMes: 0
    },
    _detalleFacturacion: []
  },
  getters: {
    dataFacturacion: state => state._dataFacturacion,
    detalleFacturacion: state => state._detalleFacturacion
  },
  mutations: {
    setDataFacturacion (state, payload) {
      state._dataFacturacion = payload
    },
    setDetalleFacturacion (state, payload) {
      state._detalleFacturacion = payload
    }
  },
  actions: {
    getDataFacturacion ({ commit }, payload) {
        return new Promise((resolve) => {
          _Facturacion.getDataFacturacion(payload).then(({ data }) => {
            commit('setDataFacturacion', data)
            resolve()
          })
        })
    },
    getDetalleFacturacion ({ commit }, payload) {
      return new Promise((resolve) => {
        _Facturacion.getDetalleFacturacion(payload).then(({ data }) => {
          commit('setDetalleFacturacion', data)
          resolve()
        })
      })
    }
  }
}
const store = createStore(storeCardFacturacion)
export default {
  namespaced: true,
  ...store
}
