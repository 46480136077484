<template>
 <div class="flex w-full my-5">
   <filtros/>
 </div>
  <div class="grid grid-rows-3 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
    <div>
      <cardFacturacion/>
    </div>
    <cardCumplimientoRef/>
    <cardCumplimientoUnd/>
    <div>
      <cardPedidos/>
    </div>
  </div>
</template>
<script>
import { computed, defineAsyncComponent, onMounted, watch } from 'vue'
import storeCardFacturacion from '../store/cardFacturacion.store'
import storeCardPedidos from '../store/cardPedidos.store'
import storeCardCumplimiento from '../store/cardCumplimiento.store'
import storeFiltro from '../store/filtro.store'
export default {
  name: 'index',
  components: {
    filtros: defineAsyncComponent(
      () => import('../components/filtro')
    ),
    cardFacturacion: defineAsyncComponent(
      () => import('../components/cardFacturacion')
    ),
    cardCumplimientoRef: defineAsyncComponent(
      () => import('../components/cardCumplimiento')
    ),
    cardCumplimientoUnd: defineAsyncComponent(
      () => import('../components/cardCumplimientoUnd')
    ),
    cardPedidos: defineAsyncComponent(
      () => import('../components/cardPedidos')
    )
  },
  setup () {
    /** computed **/
    const _anio = computed(() => storeFiltro.getters.anio)
    const _mes = computed(() => storeFiltro.getters.mes)
    /** watch **/
    watch(_anio, (a, b) => {
      getData()
    })
    watch(_mes, (a, b) => {
      getData()
    })
    /** methodos **/
    const getData = () => {
      Promise.all([
        storeCardFacturacion.dispatch('getDataFacturacion', { anio: _anio.value, mes: _mes.value }),
        storeCardCumplimiento.dispatch('getDataCumplimiento', { anio: _anio.value, mes: _mes.value }),
        storeCardPedidos.dispatch('getDataPedidos', { anio: _anio.value, mes: _mes.value })
      ])
    }
    /** hook **/
    onMounted(() => {
      getData()
    })
  }
}
</script>
<style scoped>
</style>
